<template>
<div v-if="getAllowFullSite" class="main content-page-no-sidebar seven-spheres">

    <div class="content-page-inner">
        <h1 class="center">Seven Spheres of Influence</h1>

        <p><img src="@/assets/spheres-graphic.jpg" /></p>

        <p>The Great Awakening Project (GAP) is committed to and focused on Unity in Christ, Awakening the Church, Influencing Culture, spreading Revival across our land, and taking the Gospel message to the ends of the earth. GAP is a coalition of leaders working collaboratively to advance the Kingdom of God by introducing a Biblical worldview in every channel of cultural influence.</p>

        <div v-for="(content, index) in contentList" :class="['sphere-section', content.sphere_code.toLowerCase() ]">
            <div class="sphere-bullet"></div>
            <div>
                <h2>{{ content.sphere }}</h2>
                <div class="sphere-section-content">
                    <div v-html="content.excerpt"></div>
                    <div v-html="content.main_content"></div>
                </div>
            </div>
        </div>

        <h2>BACKBONE OPERATIONS</h2>
        <h3>CORPORATE SUPPORT</h3>
        <p>With a commitment to visionary and fiduciary responsibility, the corporate backbone of GAP establishes and maintains the framework for fiduciary responsibility, support and facilitation of the Vision, Purpose, and Mission of the Movement. Also, to be the strategic, operational, and financial “structured for performance” arm of GAP, to amass necessary force multipliers to increase effectiveness in support of and through all seven spheres of cultural influence.</p>
        <ul style="margin: 0 0 0 2em;">
            <li>Fund Development: for both operational and sphere task force projects</li>
            <li>Technology: custom built, privately secured, platform</li>
            <li>Communications Strategies: website, social media, newsletters, mass mailings</li>
            <li>Policy: procedures and best practices for professional and ethical governance</li>
        </ul>

    </div>
</div>
</template>

<script>
export default {
    name: 'seven-spheres',
    data() {
        return {
            loading: false,
            contentList: [],
            selectedSpehere: ''
        }
    },
    created() {
        if (!this.getAllowFullSite) {
            this.$router.push('/coming-soon')
        } else {
            this.getContent()
        }
    },
    mounted() {
        if (this.$route.hash) {
            var element = document.getElementById(this.$route.hash);
            var top = element.offsetTop;
            window.scrollTo(0, top - 12);
        } else {
            window.scrollTo(0, 0);
        }
    },
    methods: {
        getContent() {
            if (this.loading) { return }
            this.loading = true
            this.apiCall({ destination: 'get_list_content_sections', data: { content_code: 'MAIN_SPHERES_PAGE' } }).then(obj => {
                this.loading = false
                if (obj.status == 'success') {
                    this.contentList = obj.list
                } else {
                    this.message = obj.message
                }
            })
        },
        goToAnchor(which) {
            let element = document.getElementById('#' + which);
            let elementTop = element.offsetTop;
            window.scrollTo({ left: 0, top: elementTop - 12, behavior: 'smooth' });
        }
    }
}
</script>
